import * as React from 'react'
import {graphql, Link, useStaticQuery} from 'gatsby'

const Layout = ({ pageTitle, children }) => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)
    return (
        <div>
            <header>{data.site.siteMetadata.title}</header>
            <nav>
                <ul className="list-disc list-inside">
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/about">About</Link></li>
                    <li><Link to="/blog">Blog</Link></li>
                </ul>
            </nav>
            <main>
                <h1 className="text-primary-500 text-4xl">{pageTitle}</h1>
                {children}
            </main>
        </div>
    )
}

export default Layout